import { isLocalhost } from "./util/debug";

const config = {
  baseURL: "https://europe-west1-yumip-prod.cloudfunctions.net"
};

if (isLocalhost()) {
  config.baseURL = "http://localhost:5001/yumip-prod/europe-west1";
}

export default config;
