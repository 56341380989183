import config from "../config";
import * as axios from "axios";
import { ReceiptResponse } from "./type";

export async function queryReceipt(receiptID: string): Promise<ReceiptResponse> {
  const result = await axios.default.get(`${config.baseURL}/receipt/${receiptID}`);
  return result.data as ReceiptResponse;
}

export function receiptDownloadURL(receiptID: string, type: "pdf" | "png"): string {
  return `${config.baseURL}/download/${receiptID}?ext=${type}`;
}
