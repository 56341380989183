



















































































































import "../assets/css/common.scss";
import "../assets/css/ticket.scss";
import { Component, Vue } from "vue-property-decorator";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
import { ApiReceiptPage, ReceiptResponse, queryReceipt, receiptDownloadURL } from "../domain";

interface TicketData {
  ticketID: string | null;
  status: string | null;
  creationTime: dayjs.Dayjs | null;
  branch: {
    id: number;
    name: string;
    address: string;
    photo: string | null;
    avatar: string | null;
  } | null;
  pointOfSaleName: string | null;
  paperPrinted: boolean | null;
  folder: null;
  additionalData: { key: string; value: string }[];
  renderedPages: ApiReceiptPage[];
}

@Component
export default class TicketView extends Vue {
  isLoading = true;
  removeDialog = false;
  removeLoading = false;
  ticket: TicketData = {
    ticketID: "",
    status: null,
    creationTime: null,
    branch: {
      id: 0,
      name: "",
      address: "",
      photo: null,
      avatar: null
    },
    pointOfSaleName: null,
    paperPrinted: null,
    folder: null,
    additionalData: [],
    renderedPages: []
  };
  get strings() {
    return {
      title: "Yumip: Recibos Online - ID #",
      receipt_title: "Recibo Online",
      cmd_mytickets: "Mis Recibos",
      cmd_share: "Compartir",
      cmd_remove: "Borrar",
      cmd_restore: "Restaurar",
      loading: "Cargando el recibo del servidor...",
      remove_title: "Borrar recibo",
      remove_question: "¿Desea enviar este recibo a la papelera?",
      remove_yes: "Borrar",
      remove_no: "Cancelar",
      page: "Página",
      err_ticket_title: "¡No se pudo cargar el el recibo!",
      err_ticket_desc: "Presiona para intentarlo de nuevo.",
      err_img_title: "¡No se pudo cargar esta página!",
      err_img_desc: "Presiona para intentarlo de nuevo.",
      err_remove: "No se pudo borrar el recibo, pruebe de nuevo.",
      err_restore: "Error restaurando el recibo"
    };
  }

  created() {
    //this.title = `Ticket #${this.$route.params.ticketID}`
    this.callService();
  }

  get branchPhoto() {
    //return branchPhotoPlaceholder;
    return null;
  }

  get ticketWidth() {
    let width = 380;
    for (const p of this.ticket.renderedPages) {
      if (p.imageWidth > width) width = p.imageWidth;
    }
    return width + 20;
  }

  get additionalProperties() {
    return [];
  }

  downloadLink(ext: "pdf" | "png"): string {
    if (!this.ticket.ticketID) return "";
    return receiptDownloadURL(this.ticket.ticketID, ext);
  }

  async callService() {
    const receiptID = this.$route.params.receiptID;
    document.title = this.strings.title + receiptID;
    this.isLoading = true;
    try {
      const receipt = await queryReceipt(receiptID);
      console.log({ receipt });
      this.showTicket(receiptID, receipt);
    } catch (err) {
      this.processError(err);
      this.ticket.ticketID = null;
    }
    this.isLoading = false;
  }

  showTicket(receiptID: string, ticket: ReceiptResponse) {
    this.ticket.ticketID = receiptID;
    this.ticket.status = ticket.status;
    this.ticket.creationTime = dayjs(ticket.creationTime, "DD/MM/YY H:mm:ss");
    this.ticket.branch = {
      id: 0,
      name: "",
      address: "",
      photo: null,
      avatar: null
    };
    this.ticket.pointOfSaleName = "";
    this.ticket.additionalData = [];
    this.ticket.renderedPages = ticket.pages || [];
  }

  processError(err: unknown) {
    console.error("TicketDetails error", err);
  }
}
